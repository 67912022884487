<template>
  <div>
    <el-card shadow="never">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" align="center" label="姓名">
        </el-table-column>
        <el-table-column prop="phone" align="center" label="联系电话">
        </el-table-column>
        <el-table-column prop="address" align="center" label="地址">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleClick(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      title="修改"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose"
    >
      <el-form
        :model="Form"
        :rules="rules"
        ref="Form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="Form.name"></el-input>
        </el-form-item>
        <el-form-item label="电话号码" prop="phone">
          <el-input v-model="Form.phone"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="Form.address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit('Form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      Form: {
        name: "",
        phone: "",
        address: "",
      },
    };
  },
  created() {
    this.getAbout();
  },
  computed: {
    rules() {
      return {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入电话号码", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      };
    },
  },
  methods: {
    async getAbout() {
      const res = await this.$get("/oauth/aboutUs");
    //   console.log(res);
      if (res.flag) {
        this.tableData.push(res.data);
      }
    },
    handleClick(row) {
      //   console.log(row);
      this.Form = row;
      this.dialogVisible = true;
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$post("/person/changeUsInfo", this.Form);
        //   console.log(res);
          if (res.flag) {
               this.dialogVisible = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
        })
        .catch((_) => {});
    },
  },
};
</script>

<style>
</style>